<template>
	<div class="comments-block">
    <div class="top-line">
      <p class="title">{{ title }} <span>({{ comments ? comments.length : 0 }})</span></p>
      <span class="button" @click="ShowAddСomment = !ShowAddСomment">{{ action }}</span>
    </div>
    <div v-if="ShowAddСomment" class="add-comment">
      <div class="textarea-group">
        <textarea :placeholder="action" v-model="newComment"></textarea>
      </div>
      <button class="button" @click="addComment()">Добавить</button>
    </div>
    <slider-comments v-if="comments.length" :comments="comments"></slider-comments>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import SliderComments from './sliders/SliderComments.vue'
export default {
  components: {
    SliderComments
  },
	data() {
		return {
      ShowAddСomment: false,
      newComment: null
		}
	},
	props: {
    title: {
      type: String,
      default: 'Комментарии'
    },
    action: {
      type: String,
      default: 'Добавить комментарий'
    },
		value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
		...mapGetters(['currentUser']),
    comments: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        this.$emit('input', newValue || [])
      }
    }
	},
	methods: {
		addComment() {
			if(!this.newComment) return false
      let comments = []
			if(this.comments) comments = this.comments
			comments.push({
				id: null,
				comment: this.newComment,
				author_name: this.currentUser.full_name,
				date: moment(Date.now()).format('DD.MM.YYYY в HH:mm')
			})
      this.comments = comments
			this.newComment = null
			this.ShowAddСomment = false
		},
	}
}
</script>

<style lang="sass" scoped>
.comments-block
  margin-bottom: 30px
  .top-line
    display: flex
    align-items: center
    justify-content: space-between
    .title
      margin: 0
      font-size: 16px
      font-weight: 700
      text-decoration: underline
      span
        color: #DE5353
        text-decoration: underline
    .button
      max-width: 208px
      max-height: 30px
      font-size: 14px
  .add-comment
    margin-top: 10px
    .button
      max-width: 105px
      height: 30px
      font-size: 14px
      border-radius: 0px 0px 5px 5px
      margin-left: auto
</style>